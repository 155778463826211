import { ModuleManagerState } from "@/includes/logic/Modules/ModulesManager";
import { isChatManager, ModuleTypesEnum } from "@/includes/logic/Modules/types";

import { Component } from "vue-property-decorator";
import Vue from "vue";
import { Location } from "vue-router";

@Component
export default class ModulesRoutesHelperMixin extends Vue {
  get isChatManagerVm() {
    return isChatManager(ModuleManagerState)
  }

  gotoDashboard() {
    const name = this.isChatManagerVm ? 'Community_dashboard' : 'NetworkDashboard'

    this.$router.push({ name })
  }

  gotoModulesCatalog(params?: Location['params']) {
    const name = this.isChatManagerVm ? 'ModulesCatalog' : 'NetworkModulesCatalog'

    this.$router.push({ name, params })
  }

  gotoActiveModules() {
    const name = this.isChatManagerVm ? 'ActiveModules' : 'NetworkActiveModules'

    this.$router.push({ name })
  }

  gotoCurrentModule(moduleGuidOrType: string | ModuleTypesEnum) {
    const name = this.isChatManagerVm ? 'CurrentModule' : 'NetworkCurrentModule'

    this.$router.push({
      name,
      params: {
        moduleGuidOrType
      }
    })
  }
}
